import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Skill tab`}</h1>
    <p>{`Although already configured in one channel, the Skills allow for the grouping of different groups of operators with a common objective.
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/5c716d2d87f59ea8181f19930140f520/9dfec/skill-tab.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "61%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAABYlAAAWJQFJUiTwAAABlElEQVQoz5VS7Y7TMBD0+z8JL4H4yemoqNpey6E7JMjXVeTLTmo7iWMP2s25FxBFwtIou/bu7Hgc8e79HXZJia8/cnwrSuRNh6xW/P0XinapSyuJvFHIaonvZQPxYXPAWVmopoLVGv+3AuDn33bE/vGJA20smqZBWVboui6WLwgBPgSOscq5b3D4qTSqzsDNHmJzOCEEj77vcblcYK3FMAwYxxFaa84pds5xLKXCNDlM08Qw1qLXBnYYMYwjxMftHm4aUZYlg4iJiL5t20IpxYOKlxc8Pz/hePqCJElxPp+5RrYtpOpx0ZrrxN12DwTPzVVVsRpCVEqgmIqpxpil0RjD1tDAWE9qxf3uAcF7SCmRpinyPEdd11evImgRuZvfHsF7fz0jSygXn4+PvGHtwJPputS4JougpnmerzkRRNKYi0/7B35Luk6SJCiKAlmW8ZXWKpehlgn/pv6qcHtaFA6vPkQPo5K1iti03l/wlovN4Ujj+KqkjEhvrbXC6OGfsbjfHeCm6dU/w8S3QDbcPjf86/wCgkygiWEfrbgAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/5c716d2d87f59ea8181f19930140f520/e93cc/skill-tab.webp 300w", "/static/5c716d2d87f59ea8181f19930140f520/b0544/skill-tab.webp 600w", "/static/5c716d2d87f59ea8181f19930140f520/68fc1/skill-tab.webp 1200w", "/static/5c716d2d87f59ea8181f19930140f520/f9630/skill-tab.webp 1236w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/5c716d2d87f59ea8181f19930140f520/eed55/skill-tab.png 300w", "/static/5c716d2d87f59ea8181f19930140f520/7491f/skill-tab.png 600w", "/static/5c716d2d87f59ea8181f19930140f520/8537d/skill-tab.png 1200w", "/static/5c716d2d87f59ea8181f19930140f520/9dfec/skill-tab.png 1236w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/5c716d2d87f59ea8181f19930140f520/8537d/skill-tab.png",
              "alt": "skill-tab",
              "title": "skill-tab",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Here is where all skills on the team are listen. In this window, you are able to manage existing skills or create new ones.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Note:
All configurations on "skill" override the options/settings chosen on “Team” or on “Skill type”.`}</p>
    </blockquote>
    <p>{`For CoreMedia, and to maintain consistency in the configuration of the platform making it a unique and centralized experience, we allow associating various delivery modes to the definition of our skills in order to be able to manage skill with operators and without operators, as is the case of distribution skills, IVRs and external IVRs, where the focus is to deliver to other operation skills.`}</p>
    <p>{`To create a new skill, it is necessary to choose one of the skill types active on this team and configure three other fields: skill name, delivery mode and if this skill will use all team members.`}</p>
    <p>{`Skills can have 4 types of delivery modes:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Operator`}</strong>{` - With this option, contacts will be delivered to an operator;`}</li>
      <li parentName="ul"><strong parentName="li">{`Distribution`}</strong>{` (only for voice type and chat type)`}
        <ul parentName="li">
          <li parentName="ul">{`These types of skills distribute contacts to other skills based on 3 algorithms:`}
            <ul parentName="li">
              <li parentName="ul">{`Weighted: Distribution based on weight (e.g., if the rule was 80% for skill A, 20% for skill B, if 10 contacts, 8 will be delivered on skill A and 2 on skill B).`}</li>
              <li parentName="ul">{`Overflow: Defines a limit per skill (e.g., deliver on skill A if availability was less than 80% of capacity, otherwise deliver on skill B).`}</li>
              <li parentName="ul">{`Load Balanced: Distributes contact to skills with more availability (e.g., if the rule was 80% for skill A, 80% for skill B, when a contact need to be distributed, the skill with more availability will be chosen until the limits of each skill are achieved).`}</li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`IVR (only for voice type)`}</strong>{`
Delivers a call to a BySide Interactive Voice Response (IVR). This feature allows the use of automated voice response menus or workflows.`}</li>
      <li parentName="ul"><strong parentName="li">{`External IVR (only for voice type)`}</strong>{`
Route the contact to an external (non CoreMedia) IVR.`}</li>
    </ul>
    <p>{`Use the `}<em parentName="p">{`save`}</em>{` button to finish the first step of this configuration. The following steps are specific, according to the skill type selected.`}</p>
    <h2>{`Skill: Voice`}</h2>
    <p>{`As presented before, a voice skill allows three types of settings according to the delivery mode.`}</p>
    <h3>{`(A) If delivery mode is “Operator”`}</h3>
    <h4>{`General`}</h4>
    <p><strong parentName="p">{`Name`}</strong>{` - The name of the skill should be self explanatory.`}</p>
    <h5>{`General Settings`}</h5>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Use all team members`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`Defines whether all users assigned to this team will be associated with this skill.`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Reversed Call Info`}</strong>{` nao tenho. que preciso
Defines whether the reversed calls are allowed.`}</li>
      <li parentName="ul"><strong parentName="li">{`Schedules (Website) Info`}</strong>{`
tdb`}</li>
      <li parentName="ul"><strong parentName="li">{`Auto assign schedule to skill`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`When creating a schedule in the console: defines whether it is to be associated with the original contact's skill or not.`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Allow Video`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`Even though it is a voice skill, it can be configured to allow the sharing of video (i.e. an image of the operator, or an image that the operator wants to share) with the contact.`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Lead Types`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`Associate lead types to this skill. All contacts will be associated with a lead of the type added in this configuration parameter.`}</li>
        </ul>
      </li>
    </ul>
    <h5>{`Voicemail`}</h5>
    <p>{`By default the settings defined in the voice skill type are used. Otherwise, the setting can be changed specifically for this skill. The options are the same as in the skill type.`}</p>
    <h5>{`Calls actions`}</h5>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Audio-only interaction mode`}</strong>{` `}
        <ul parentName="li">
          <li parentName="ul">{`Disable operator's microphone and voice interaction, allowing only pre-recorded audio messages to be selected and played from the interface.`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Operator access to voice actions`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`Allow the operator to access and select audio messages or IVR actions from the Voice Actions tab in the ByCard.`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Wrap-up IVR`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`Allow to select an IVR schema to be played when the call ends. Can be used to play a customer survey or additional info to customer.`}</li>
        </ul>
      </li>
    </ul>
    <h5>{`Recordings`}</h5>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Automatic Call Recording`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`When enabled (Yes), all calls associated to this skill will be automatically recorded in full length.`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Allow Partial Call Recording`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`When enabled (Yes), the operator has the possibility to record parts of the call, with the ability to start and stop the recording several times and append the several recordings to a single file. To control this feature, an additional button will be presented to the operator on the bycard.`}</li>
        </ul>
      </li>
    </ul>
    <h5>{`Priority`}</h5>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Mode`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`Define one type of call as priority to this skill. The call types available are: inbound, click2call, recoveries, outbound campaigns and media/affiliates. For example: if click2call is chosen, calls of this type will be made first, to the detriment of the others.`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Level`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`Used in the scheduled call service to sort the schedules and launch the highest priority ones first.
For example: if Click2call has priority 1, API calls has priority 4 and recoveries has priority 9; If there are calls scheduled to be launched, the lowest priority calls go out first, even if the oldest priority 9 call is already 10 days old.`}</li>
        </ul>
      </li>
    </ul>
    <h5>{`Transfer`}</h5>
    <p>{`On transfers there are the same possibilities as in skill type. You can either inherit the settings of the skill type, add a list of skills to which the call can be transferred or a list of external numbers.`}</p>
    <p>{`Transfer types:`}</p>
    <p>{`The blind transfers will work exactly as they were created initially but within the new transfer overlay.
Operator selects where to transfer and blind transfer type. Once it's answered Operator 1 abandons the call.`}</p>
    <p>{`Attended transfers allow both operators to talk before transferring the visitor/contact to a new operator. During this period the contact will be on hold and the first operator has the control of transfering the call or cancel it.`}</p>
    <h5>{`Queue position & estimate wait time`}</h5>
    <p>{`By default the settings defined in the voice skill type are used. Otherwise, the setting can be changed specifically for this skill. The options are the same as in the skill type.`}</p>
    <h5>{`Smartwindow`}</h5>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Ecommerce context`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`Configure ecommerce app.`}</li>
        </ul>
      </li>
    </ul>
    <h5>{`Contact attempts mode`}</h5>
    <p>{`This mode allows you to define how attempts will be managed. The possibilities are:`}</p>
    <ul>
      <li parentName="ul">{`Global Settings: defined when customer was created;`}</li>
      <li parentName="ul">{`Fixed Intervals: define the number of attempts and the interval (in seconds);`}</li>
      <li parentName="ul">{`Specific Intervals (according to the number of attempts): it is possible to define the cadences of contact attempts (in seconds) and set different waiting times for retrying;`}</li>
      <li parentName="ul">{`Workflow: attempts will be launched by automation up to a preset maximum time (in minutes). Maximum time allowed is 31 days (44640min);`}</li>
    </ul>
    <h5>{`Answering mode`}</h5>
    <p>{`By default the settings defined in the voice skill type are used. Otherwise, the setting can be changed specifically for this skill. The options are the same as in the skill type.`}</p>
    <h5>{`Outbound contact`}</h5>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Allow outbound calls`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`Operator can launch calls and use the Dialer/webphone to start  a call.`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Allow outbound contact on scheduled calls`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`Operator can launch calls from Schedule app.`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Automatically start outbound contact on scheduled calls`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`By selecting Yes, the scheduled call will be launched automatically. A 15-second timer is shown to the operator to make him aware that he is about to handle a scheduled outbound call and to read available information from the contact.`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Automatically outbound contact exit if activated automatically`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`By selecting Yes, the system automatically changes from outbound to inbound.`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Existe after (seconds)*`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`It is possible to define how long will take to change from outbound to inbound.`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Select outbound channel`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`A way of grouping  the outbounds according to business rules.`}</li>
        </ul>
      </li>
    </ul>
    <h5>{`Parallel calls`}</h5>
    <p>{`It is available when Limit parallel calls is Always or Only scheduled contacts or Never on  Call app > Settings > General Settings and Apply limit per skill is Yes. `}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Limit parallel calls`}</strong></p>
        <ul parentName="li">
          <li parentName="ul">{`You can opt for enabling or disabling of parallel calls:`}
            <ul parentName="li">
              <li parentName="ul">{`Settings: defined when customer was created;`}</li>
              <li parentName="ul">{`Never;`}</li>
              <li parentName="ul">{`Only Scheduled contacts: when enabled, you can define how many simultaneous calls are allowed;`}</li>
              <li parentName="ul">{`Always: when enabled, you can define how many simultaneous calls are allowed;`}</li>
              <li parentName="ul">{`Dynamic: when enabled, the number of free operators is taken into account when launching the call. This feature only works for clients that use the console, either CTI or Cloud Contact Center.`}</li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Actions on limit calls in parallel`}</strong></p>
        <ul parentName="li">
          <li parentName="ul">{`When the parallel call limit is reached, the system can be configured to present a message or wait.`}</li>
        </ul>
      </li>
    </ul>
    <h5>{`Caller ID`}</h5>
    <p><strong parentName="p">{`Show Caller ID`}</strong></p>
    <p>{`Use the skill-type definition or customize the number to be shown to the visitor by entering the desired number. It is possible to make calls without showing the origin number to the visitor. To do so, configure this field as empty.`}</p>
    <h5>{`Audios`}</h5>
    <p><strong parentName="p">{`Intro audio`}</strong></p>
    <p>{`It is possible to define an audio to be played as intro.`}</p>
    <p><strong parentName="p">{`Music on hold`}</strong></p>
    <p>{`It is possible to define an audio to be played when the operator puts the customer on hold.`}</p>
    <h5>{`Survey (or contact classification)`}</h5>
    <p>{`This is one of the most critical functionalities of CoreMedia Engagement Cloud. Classifying contacts is important to understand customers, businesses, and needs. Here, you can configure the following:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Client satisfaction survey`}</strong>{` - Describes the client opinion/satisfaction`}</li>
      <li parentName="ul"><strong parentName="li">{`Client voice satisfaction survey`}</strong>{` - Describes the client opinion/satisfaction using a voice survey`}</li>
      <li parentName="ul"><strong parentName="li">{`Contact classification form`}</strong>{` - Details the contact output`}</li>
      <li parentName="ul"><strong parentName="li">{`Classification form of schedules`}</strong>{` - Describes the reason for scheduling a new contact`}</li>
      <li parentName="ul"><strong parentName="li">{`External contact classification form`}</strong>{` - Details the external contact`}</li>
    </ul>
    <h5>{`Script`}</h5>
    <p>{`It’s a manual that an operator can refer to with all notes and insights that they should use when interacting with customers.`}</p>
    <h5>{`Offers`}</h5>
    <p>{`Contacts can be correlated with customers' promotional offers/campaigns. By enabling this feature it is possible to indicate a static or dynamic offer.`}</p>
    <h5>{`Artificial intelligence`}</h5>
    <p><strong parentName="p">{`Transcription of voice actions`}</strong></p>
    <ul>
      <li parentName="ul">{`Transcription activation`}
        <ul parentName="li">
          <li parentName="ul">{`By setting Transcription activation to Yes, the transcripts of call or chat will occur.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Language`}
        <ul parentName="li">
          <li parentName="ul">{`Automatic detection of the call.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Business keywords (up to 50 words)`}
        <ul parentName="li">
          <li parentName="ul">{`Possible to define up to 50 words that could help the system to recognize them.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Contact indicators`}
        <ul parentName="li">
          <li parentName="ul">{`Indicators activation`}
            <ul parentName="li">
              <li parentName="ul">{`Possible to activate selecting a manual or automatic option`}</li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    <h5>{`Timetables`}</h5>
    <p><strong parentName="p">{`Define schedule in the skill`}</strong></p>
    <ul>
      <li parentName="ul">{`Define working hours and days off. By default, a timetable is defined on "Skill Type.`}</li>
    </ul>
    <p><strong parentName="p">{` Beyond business hours`}</strong></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Action`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`Show message - A message is shown when after working hours.`}</li>
          <li parentName="ul">{`Schedule contacts - Possible to define slots for scheduling.`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Beyond business hours audio`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`An audio can be selected to be played to customers.`}</li>
          <li parentName="ul">{`When for Action is selected Schedule contacts:`}
            <ul parentName="li">
              <li parentName="ul">{`Scheduling blocks - time slots during which schedule is possible`}</li>
              <li parentName="ul">{`Reverse call in schedules `}</li>
              <li parentName="ul">{`Limit call - Yes/No `}</li>
              <li parentName="ul">{`Call limit on each interval -  how many calls are possible to schedule for each slot`}</li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    <h3>{`(B) If delivery mode is “Distribution"`}</h3>
    <p>{`If the skill is Distribution, you can choose one of the three existing distribution methods (already explained before), set the possibility of voicemail detection and the answering time for this distribution skill.
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "866px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/8c987888d9ca22d333ffd7d1f6e7e045/ba4a5/distribution.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "87.00000000000001%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAYAAADdRIy+AAAACXBIWXMAABYlAAAWJQFJUiTwAAACHUlEQVQ4y6WU3U4TQRiG98gT78E7NMYLMPGcmzDxRCGKBgEp8cBwoERAaWu3XRSapt1Sl3a7P213Zmf3MTO7BVKhmDDJk/3J7Pu938+stbJ1wNHZgMZZF7vdpe/HuH50I/0luKOQ3ijAerFXZxwnhGHANI6477Je79vmRqoM3x/jeR6DwQC33yeMInIgy7Ll5DnBJEFIhfXqawNSSbfXIwxDsyFNU4NS6m6xBazV0uE4CJFS/pNCnue3onRwVSBTZZ6ttW9NSBPjhjK9JEkQQiwlEULvLsMqUAkoifXmoMkknnDqXiBSZSILIU2AeeqLyDSFTPHlt8fL/TZrhx1zPe4MC0GRCJzOOUM/MPHSUlgzr838fp6qXo9Xf/Dw6XsePdvmwZN3rFTsQlBbHY38y4+1i2WFVyrTxaXWGVKpdvls99mt9fjV97Vg0ZRZIi5daIe3ic33QI7j+nw/8zg5D2i6Y/6MY6y3h02QgngyvWzKdYe6lteZv8vzjLYXcnDqUe0M2bNdnK6Hta4Fdd3KLl+v210sDJiZDmv9qBCUZZpa8H+XCV5ihkcpnXJRQyGvTseNQ7wwRlf1LI1kmTkYhWAqiaczMw5ZORaLBGFIFMdMZzODOSV5TjToMR1dMA184jjC2qo6RZej8N5/GnTKzzf22G2csnHs8KHqsFltsVlrsaWpO2zXW2z/dPhYstMoqNgFu82CT60Tdho2fwFtpRV3K7ezrQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/8c987888d9ca22d333ffd7d1f6e7e045/e93cc/distribution.webp 300w", "/static/8c987888d9ca22d333ffd7d1f6e7e045/b0544/distribution.webp 600w", "/static/8c987888d9ca22d333ffd7d1f6e7e045/f1046/distribution.webp 866w"],
              "sizes": "(max-width: 866px) 100vw, 866px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/8c987888d9ca22d333ffd7d1f6e7e045/eed55/distribution.png 300w", "/static/8c987888d9ca22d333ffd7d1f6e7e045/7491f/distribution.png 600w", "/static/8c987888d9ca22d333ffd7d1f6e7e045/ba4a5/distribution.png 866w"],
              "sizes": "(max-width: 866px) 100vw, 866px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/8c987888d9ca22d333ffd7d1f6e7e045/ba4a5/distribution.png",
              "alt": "distribution",
              "title": "distribution",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h4>{`General`}</h4>
    <h4>{`Methods`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Weighted`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`Each descending skill has an associated weight whose sum weights must give 100%. These weights represent the percentage of call allocation to a skill, that is, for the example shown in the diagram below, after 50 calls it would be expected to see each skill with 25 calls allocated.`}</li>
          <li parentName="ul">{`Distribution skills with this method do not generate a queue. All calls that arrive are distributed respecting the probability of delivery for each operator skill. If there are no free operators, the call is on hold using the chosen operator skill.`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Overflow`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`The call will attempt to be delivered in order of priority of the descending skills. In order, we obtain the number of active operators for each descendant, with the maximum load applied to this number. The load is given by the number of calls taking place over the number of active operators. If the skill load, including the future call, is below the maximum load defined for that skill, then the call will be delivered to that skill. Otherwise, we will repeat the process for the next descendant skill.`}</li>
          <li parentName="ul">{`If concurrent calls are delivered to the distribution skill (e.g. "simultaneous" C2C requests or inbound calls), if there is no capacity in the operator skills, the call is on hold in the distribution skill.`}</li>
          <li parentName="ul">{`Then, in order, delivery is respected as calls are ended and operators are free.`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Load balanced`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`First we obtain the number of active operators for each descending skill, with the maximum load applied to this number. The load is given by the number of calls taking place over the number of active operators. If the load of a descendant skill, including the future call, is the lowest load among all descendants and is below the maximum limit, then the call will be delivered to this skill. If there are ties, one of these skills is chosen at random.`}</li>
          <li parentName="ul">{`In this method, the same concept of concurrency as that of Overflow applies.`}</li>
        </ul>
      </li>
    </ul>
    <h4>{`Timetable`}</h4>
    <p>{`The working hours definition of a Delivery skill is the result of the operator skills added. Custom distribution is defined directly in the distribution skill.`}</p>
    <h3>{`(C) If delivery mode is “IVR”`}</h3>
    <p>{`Similar to the previous mode, in the IVRs we can define which IVR flow will be presented as well as configure the voicemail detection, AI indicators and the time availability of this skill. `}</p>
    <h3>{`(D) If delivery mode is “IVR External”`}</h3>
    <p>{`Similar to the distribution mode, in the external IVRs we can define the destination number to which that call will be delivered as well as:`}</p>
    <ul>
      <li parentName="ul">{`Configure voicemail detection;`}</li>
      <li parentName="ul">{`Configure attempts mode;`}</li>
      <li parentName="ul">{`Configure caller id;`}</li>
      <li parentName="ul">{`Define surveys to validate client satisfaction and contact classification;`}</li>
      <li parentName="ul">{`Timetables of this skill.`}</li>
    </ul>
    <p>{`All these settings are the same as described above.`}</p>
    <h2>{`Skill: Chat`}</h2>
    <p>{`As presented for voice skill, in chat there are 3 delivery modes: Operator, Distribution and Chatbot.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "996px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/f46888d50f71c42b76dd36b1ec1f3ed1/14920/chat-tab.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "112.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAYAAADAQbwGAAAACXBIWXMAABYlAAAWJQFJUiTwAAABdklEQVQ4y6WVC4+CMBCE+///5OWCOfFUUCh90MfcTbEgSEwEko3Yx/jt7rSKGCO89zDGwDkHPhxbPmtja4+QUqKqqtnGPSFIpbVOlCGEl8gLuW76HubxGAcihFIKfd+/JeR8VdVQWoPbQogIcQpq9b1LUMJamwhzZIpnQu8Dvr4L/JS/6IxF22m0agqpDBqpYHsHQRESMCXGko40nC/LEpfLhSkMKS4jDgBiWatRaBQc5tu2BbMZUg4va/OYWLPEUvzdXG5GnhOZgj6khdZo3wXFnA/Q1kHZfkq56zrcbrePBJkqa2qdh9QWrTaDD1nsbO4tZn5sHATpnaZpQD/uOSmpcZmQdKfTCff7fbPoKEhCNqOu6/S5R1Bmwuv1moyb6/Fpp18Iadrz+ZxEad4tlDxR0jx1mZbhEeMPbE7ZPBHS2Hu7PBLWdTU2ZOtFOxOkB1nD4/GIoijSqflUdCbIy/NwOIz/K1vqOAryhSIU4/seH5r/C/YP1RzJACkdcNUAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/f46888d50f71c42b76dd36b1ec1f3ed1/e93cc/chat-tab.webp 300w", "/static/f46888d50f71c42b76dd36b1ec1f3ed1/b0544/chat-tab.webp 600w", "/static/f46888d50f71c42b76dd36b1ec1f3ed1/46197/chat-tab.webp 996w"],
              "sizes": "(max-width: 996px) 100vw, 996px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/f46888d50f71c42b76dd36b1ec1f3ed1/eed55/chat-tab.png 300w", "/static/f46888d50f71c42b76dd36b1ec1f3ed1/7491f/chat-tab.png 600w", "/static/f46888d50f71c42b76dd36b1ec1f3ed1/14920/chat-tab.png 996w"],
              "sizes": "(max-width: 996px) 100vw, 996px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/f46888d50f71c42b76dd36b1ec1f3ed1/14920/chat-tab.png",
              "alt": "chat-tab",
              "title": "chat-tab",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3>{`(E) If delivery mode is “operator”`}</h3>
    <p>{`Describing each section:`}</p>
    <h4>{`General`}</h4>
    <p><strong parentName="p">{`Skill name`}</strong></p>
    <p>{`The name of the skill.`}</p>
    <p><strong parentName="p">{`Use all team members`}</strong></p>
    <p>{`If enabled (Yes), this skill becomes associated with all users in this teams.`}</p>
    <p><strong parentName="p">{`Security filters`}</strong></p>
    <p>{`Apply the security filters already defined on Customer Configurations (list of IPs permitted).`}</p>
    <p><strong parentName="p">{`Emoji usage`}</strong></p>
    <p>{`Operators can use emojis in a conversation.`}</p>
    <p><strong parentName="p">{`Writing notifications`}</strong></p>
    <p>{`Visitors see when the operator is writing.`}</p>
    <p><strong parentName="p">{`Automatic acceptance`}</strong></p>
    <p>{`When disabled (No), the operator receives a notification on the console for each contact and has to manually accept the contact.`}</p>
    <p><strong parentName="p">{`Opening of new chats`}</strong></p>
    <p>{`When a new chat is opened, there is the possibility to open the bycard in front of “Open in foreground” or behind “Open in background” all other windows.`}</p>
    <p><strong parentName="p">{`Full transcript (same visitor)`}</strong></p>
    <p>{`Join all conversations from one visitor's contacts of this skill.`}</p>
    <p><strong parentName="p">{`Allow file transfer (direct)`}</strong></p>
    <p>{`Allow sending and receiving files.`}</p>
    <p><strong parentName="p">{`Operator ring time`}</strong></p>
    <p>{`Maximum chat delivery time (in seconds) per operator. After this time, the chat will be pre-allocated to another operator according to the delivery rules.`}</p>
    <p><strong parentName="p">{`Max active chats per operator`}</strong></p>
    <p>{`Maximum number of simultaneous chats per operator.`}</p>
    <blockquote>
      <p parentName="blockquote">{`IMPORTANT: When "Include finalized chats" is set to "Yes," chats without Classification count as active chats (e.g., if the maximum number equals 4, an operator can have no more than 4 unfinished or non classified chats). When set up as "No," the nonclassified chats are not counted toward the operator's capacity. The maximum value allowed is 99.`}</p>
    </blockquote>
    <p><strong parentName="p">{`Lead Types`}</strong></p>
    <p>{`Allows to correlate contacts from a given visitor. A lead has a sales/conversion goal. It can be associated with different contact types, campaigns, and skills.`}</p>
    <p><strong parentName="p">{`Operator can transfer chats`}</strong></p>
    <p>{`This parameter allows or disallows operators in this skill to transfer contacts to other skills. By default the value defined on skill type is used.`}</p>
    <p><strong parentName="p">{`Limit chat acceptance`}</strong></p>
    <p>{`If the limit for chat acceptance was configured as "Yes," consider the current defined capacity (e.g., if limited to 50%, only accept a transferred contact if the capacity at that time is less than 50%).`}</p>
    <p><strong parentName="p">{`Smart Windows: Ecommerce Context`}</strong></p>
    <p>{`Feature that allows the operator to suggest products to the visitor. The visitor can see and interact with these suggestions in the contact window. See more details in Cloud Contact Center documentation.`}</p>
    <h4>{`Survey`}</h4>
    <p>{`This is one of the most critical functionalities of Engagement Cloud. Classifying contacts is important to understand customers, businesses, and needs. It is possible to configure the following:`}</p>
    <ul>
      <li parentName="ul">{`Contact classification form - Details the contact output`}</li>
      <li parentName="ul">{`Classification form of schedules - Describes the reason for scheduling a new contact;`}</li>
      <li parentName="ul">{`External contact classification form - Details the external contact.`}</li>
    </ul>
    <h4>{`Service script`}</h4>
    <p>{`It is a manual that a call center attendant can refer to with all the notes and insights that they should use when interacting with customers.`}</p>
    <h4>{`Messages`}</h4>
    <p>{`In this stepping you can define 2 messages for all contacts that are associated with this skill:`}</p>
    <ul>
      <li parentName="ul">{`Welcome message;`}</li>
      <li parentName="ul">{`Direct chat - transfer text (operator unavailable).`}</li>
    </ul>
    <h4>{`Finishing options`}</h4>
    <p>{`When a chat is closed, you have the option to define the following:`}</p>
    <ul>
      <li parentName="ul">{`Send conversation by email;`}</li>
      <li parentName="ul">{`Print conversation.`}</li>
    </ul>
    <h4>{`Alerts`}</h4>
    <p>{`Notify a list of users (usually Supervisors) when there is a queue in the operation and one chat lacks response.`}</p>
    <h4>{`Offers`}</h4>
    <p>{`You can associate the contact of this skill with a specific offer/campaign promoted on a website or other customer channels.`}</p>
    <h4>{`Timetable`}</h4>
    <p>{`Define working hours and days off. By default, a timetable is defined on "Skill Type.”`}</p>
    <h3>{`(E) If delivery mode is “Distribution”`}</h3>
    <p>{`Similar to voice distribution skill.`}</p>
    <h2>{`Skill: Ticketing`}</h2>
    <p>{`For this channel, the configuration is simpler compared to the other channel types already presented. The major difference is that there is only the "operator" distribution mode. `}</p>
    <h3>{`General`}</h3>
    <h4>{`Name`}</h4>
    <p>{`The name of the skill should be self explanatory.`}</p>
    <h4>{`Use all team members`}</h4>
    <p>{`Defines whether all users assigned to this team will be associated with this skill.`}</p>
    <h4>{`Answering mode`}</h4>
    <p>{`Defines the way a ticket is delivered to the operator. At this moment the ticket is automatically delivered to one operator.`}</p>
    <h4>{`Answering strategy`}</h4>
    <p>{`Defines ticket routing strategy, who is the operator to receive a contact. The options could be according to defined on skill type or one of the following strategies:`}</p>
    <ul>
      <li parentName="ul">{`Occupation rate: tickets are delivered to the operator with lower occupancy rate`}</li>
      <li parentName="ul">{`Random: tickets are randomly assigned to an operator`}</li>
      <li parentName="ul">{`Round Robin: tickets are distributed equally by the operators instead of sending more tickets to one operator over the other.`}</li>
    </ul>
    <h4>{`Set attribution period`}</h4>
    <p>{`Only operators logged less than X days are eligible for the previous distribution mode. It is possible to use the configuration of skill type.`}</p>
    <h3>{`Email settings`}</h3>
    <h4>{`Inbox, IMAP Server and Port`}</h4>
    <p>{`The address of the incoming mail server and port for an IMAP account`}</p>
    <h4>{`Inbox, Email and Password`}</h4>
    <p>{`The credential to access to mail server for IMAP account`}</p>
    <h4>{`Outbound, SMTP Server and Port`}</h4>
    <p>{`The address of the outgoing mail server and port`}</p>
    <h4>{`Outbound, Email and Password`}</h4>
    <p>{`The credential to access the SMTP server`}</p>
    <h3>{`Survey (or Contact classification)`}</h3>
    <p>{`This is one of the most critical functionalities of CoreMedia. Classifying contacts is important to understand customers, businesses, and needs. It is possible to configure the Contact Classification form.`}</p>
    <h3>{`Script`}</h3>
    <p>{`It is a manual that a call center attendant can refer to with all the notes and insights that they should use when interacting with customers.`}</p>
    <h2>{`Skill: Forms`}</h2>
    <p>{`For this channel, the configuration is basic.`}</p>
    <h3>{`General`}</h3>
    <h4>{`Name`}</h4>
    <p>{`The name of the skill should be self explanatory.`}</p>
    <h4>{`Use all team members`}</h4>
    <p>{`Defines whether all users assigned to this team will be associated with this skill.`}</p>
    <h3>{`Survey (or Contact classification)`}</h3>
    <p>{`This is one of the most critical functionalities of CoreMedia. Classifying contacts is important to understand customers, businesses, and needs. It is possible to configure the Contact Classification form.`}</p>
    <h3>{`Script`}</h3>
    <p>{`It is a manual that a call center attendant can refer to with all the notes and insights that they should use when interacting with customers.`}</p>
    <h3>{`Offers`}</h3>
    <p>{`Contacts can be correlated with customers' promotional offers/campaigns. By enabling this feature it is possible to indicate a static or dynamic offer.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      